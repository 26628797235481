import PropTypes from "prop-types";
import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import fetch from "isomorphic-fetch";

import Header from "./header";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { contactHref } from "../data/contact-href";

import twitterSvg from "../images/twitter.svg";
import facebookSvg from "../images/facebook.svg";
import { Tooltip } from "@material-ui/core";

const uri =
  process.env.GATSBY_PROD_GRAPHQL ||
  "https://zetsscpbr0.execute-api.us-east-1.amazonaws.com/dev/graphql";
const client = new ApolloClient({
  fetch,
  uri,
});

const StyledButton = withStyles((theme) => ({
  root: {
    color: `#0F4D4A`,
    fontFamily: `Raleway`,
    fontWeight: `bold`,
    textTransform: `none`,
  },
}))(Button);

function Layout({ children, hideCreate }) {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider
        theme={createMuiTheme({
          palette: {
            primary: {
              main: "#55D3B8",
            },
          },
        })}
      >
        <div className="flex flex-col items-center font-sans min-h-screen text-gray-900">
          <Header hideCreate={hideCreate} />
          <main className="w-full mb-32">{children}</main>
          <footer className="w-full py-6 px-10 mt-auto flex">
            <Tooltip title="Twitter">
              <a href="https://twitter.com/vegeboxes" className="mt-1">
                <span className="sr-only">twitter</span>
                <img alt="twitter" src={twitterSvg} />
              </a>
            </Tooltip>
            <Tooltip title="Facebook">
              <a href="https://www.facebook.com/vegeboxes/" className="ml-4">
                <span className="sr-only">facebook</span>
                <img alt="facebook" src={facebookSvg} />
              </a>
            </Tooltip>
            <span className="ml-auto inline-block">
              <StyledButton href={contactHref}>Contact us</StyledButton>
            </span>
          </footer>
        </div>
      </ThemeProvider>
    </ApolloProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
