import { Link } from "gatsby";
import React from "react";
import boxSvg from "../images/box.svg";

function Header({ hideCreate }) {
  return (
    <>
      <nav className="flex items-center p-6 max-w-4xl w-full">
        <Link to="/" className="flex items-center">
          <img alt="Empty Box" className="block mr-2 h-10 w-10" src={boxSvg} />
          <h1 className="text-lg font-bold">Vegeboxes</h1>
        </Link>
        <div className="ml-auto flex items-center">
          {!hideCreate && (
            <Link
              to="/create"
              className="mr-4 inline-block transition-bg transition-100 transition-ease hover:bg-green-500 bg-green-600 rounded-lg font-bold text-white px-4 py-1 ml-2 tracking-wider text-lg"
            >
              CREATE
            </Link>
          )}
        </div>
      </nav>
    </>
  );
}

export default Header;
